var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row py-3 justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-6"},[_c('ul',{staticClass:"list-group mb-4 mt-4"},_vm._l((_vm.moves),function(move){return _c('li',{key:move.id,staticClass:"list-group-item"},[_c('div',{staticClass:"moves-item"},[_c('router-link',{attrs:{"to":{
								name: 'PlayerHome',
								params: {
									lang: _vm.lang,
									console: _vm.console,
									playerSlug: move.slug,
								},
							}}},[_c('img',{staticClass:"moves-item__avatar",attrs:{"src":move.avatar,"alt":move.nick}})]),_c('div',{staticClass:"moves-item__details"},[_c('small',{staticClass:"d-block mb-4 text-muted"},[_vm._v(_vm._s(_vm.$t('date'))+": "+_vm._s(move.date))]),(move.action === 'IN')?_c('h6',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('transferIn', {
										player: move.nick,
										team: move.team_name,
									}))+"\n\t\t\t\t\t\t\t")]):_c('h6',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('transferOut', {
										player: move.nick,
										team: move.team_name,
									}))+"\n\t\t\t\t\t\t\t")])]),_c('router-link',{attrs:{"to":{
								name: 'TeamHome',
								params: {
									lang: _vm.lang,
									console: _vm.console,
									teamSlug: move.team_slug,
								},
							}}},[_c('img',{staticClass:"moves-item__team",attrs:{"src":move.custom_image_team,"alt":move.team_name}})])],1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }