<template>
	<div class="container-fluid">
		<div class="row py-3 justify-content-center">
			<div class="col-12 col-md-8 col-lg-6">
				<ul class="list-group mb-4 mt-4">
					<li v-for="move in moves" :key="move.id" class="list-group-item">
						<div class="moves-item">
							<router-link
								:to="{
									name: 'PlayerHome',
									params: {
										lang: lang,
										console: console,
										playerSlug: move.slug,
									},
								}"
							>
								<img
									:src="move.avatar"
									:alt="move.nick"
									class="moves-item__avatar"
								/>
							</router-link>
							<div class="moves-item__details">
								<small class="d-block mb-4 text-muted"
									>{{ $t('date') }}: {{ move.date }}</small
								>
								<h6 v-if="move.action === 'IN'">
									{{
										$t('transferIn', {
											player: move.nick,
											team: move.team_name,
										})
									}}
								</h6>
								<h6 v-else>
									{{
										$t('transferOut', {
											player: move.nick,
											team: move.team_name,
										})
									}}
								</h6>
							</div>
							<router-link
								:to="{
									name: 'TeamHome',
									params: {
										lang: lang,
										console: console,
										teamSlug: move.team_slug,
									},
								}"
							>
								<img
									:src="move.custom_image_team"
									:alt="move.team_name"
									class="moves-item__team"
								/>
							</router-link>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			moves: [],
		}
	},
	computed: {
		...mapGetters(['lang', 'console']),
	},
	created() {
		this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'home')
		this.fetchData()
	},
	methods: {
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.$route.params.tournamentSlug
			const path = `${lang}/console/${console}/tournament/${slug}/movements`
			this.$axios.get(path).then((response) => {
				this.moves = response.data.data
			})
		},
	},
}
</script>
